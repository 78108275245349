body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
a {
  color: unset;
  text-decoration: unset;
}

html,
body,
#root {
  height: 100%;
}

button,
input[type="submit"] {
  font-weight: normal;
  margin-top: 1rem;
  height: 3rem;
  background-color: #0c6edb;
  color: white;
  -webkit-tap-highlight-color: transparent;
  align-items: center;
  border: 0;
  border-radius: 3px;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  letter-spacing: 1px;
  outline: 0;
  padding: 0 16px;
  text-decoration: none;
  text-transform: uppercase;
  text-rendering: optimizeSpeed;
  overflow: hidden;
  transition: background-color 0.2s, box-shadow 0.2s;
  vertical-align: middle;
  white-space: nowrap;

  &:hover {
    box-shadow: 0 2px 4px -1px #666;
    outline: 0;
    text-decoration: none;
  }
}

.container-xl {
  max-width: 80rem;
}

.Imprint {
  p,
  h1,
  h2,
  h3 {
    margin-top: 1rem;
  }
}

ul,
menu,
dir {
  display: block;
  list-style-type: disc;
  -webkit-margin-before: 1em;
  -webkit-margin-after: 1em;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  -webkit-padding-start: 40px;
}

ol {
  display: block;
  list-style-type: decimal;
  -webkit-margin-before: 1em;
  -webkit-margin-after: 1em;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  -webkit-padding-start: 40px;
}
