@import url("https://fonts.googleapis.com/css2?family=Lalezar&display=swap");

header {
  background-color: #1a2938;
}

.logo {
  font-family: "Lalezar", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
}

.logo-img {
  pointer-events: none;
  margin-right: 0.5rem;
  background-color: #000a1b;
  border-radius: 0.5rem;
  padding: 4px;
}

.header-content {
  display: flex;
  align-items: center;
}

.header-item {
  font-weight: lighter;
  font-size: 1rem;
  padding-left: 1rem;
  margin-left: 1rem;
  padding-right: 1rem;
  margin-right: 1rem;
  height: 3.5rem;
  align-items: center;
}

.active {
  font-weight: normal;
  border-bottom: 4px #8db5ce solid;
}

.company {
  margin-top: 0.25rem;
}

.content {
  text-align: left;
  font-size: 1rem;
  margin: auto;
}
